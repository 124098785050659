html {
  font-size: 16px;
  @media (max-width: 480px) {
    //font-size: 40px;
    body {
      padding-right: 0 !important;
    }
  }
  @media (min-width: 1920px) {
    font-size: 18px;
  }
}
